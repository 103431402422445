<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div
          class="top-line"
          :style="{
            background: backgroundColor,
            backgroundImage: backgroundImage
              ? `url(${backgroundImage})`
              : undefined,
          }"
        >
          <div class="row">
            <div class="col-12 col-lg-6">
              <h1 class="top-line__title">{{ title }}</h1>
            </div>
            <div class="col-12 col-lg-6 text-right">
              <img
                v-if="image"
                :src="image"
                :alt="title"
                class="top-line__image m-lg-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopLine",
  props: {
    backgroundColor: {
      type: String,
      default: "linear-gradient(90deg, #613eea,#9e33e6,#ff7b00)",
    },
    backgroundImage: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
.top-line {
  min-height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;
  padding: 40px;
  margin-bottom: 50px;
  @include media(1200) {
    min-height: 300px;
    padding: 20px;
  }
  &__title {
    color: #fff;
    font-size: em(45);
    font-weight: 700;
    @include media(992) {
      font-size: em(32);
    }
  }
  &__image {
    max-width: 100%;
    vertical-align: top;
  }
}
.text-right {
  text-align: right;
}
</style>
