<template>
  <div>
    <template v-if="$route.name === 'NewsDetail'">
      <top-line
        v-if="getActualNews.title"
        :image="`${getFilePrefix}${getActualNews.detail_picture}`"
        :title="getActualNews.title"
      ></top-line>
    </template>
    <template v-if="$route.name === 'EventDetail'">
      <top-line-city
        v-if="getActualNews.title"
        :title="getActualNews.title"
        :image="`${getFilePrefix}${getActualNews.detail_picture}`"
      ></top-line-city>
    </template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 pt-4">
          <div class="col-12 pb-3 back">
            <a @click="$router.go(-1)">Назад</a>
          </div>
          <div class="row">
            <div class="col-12 col-md-9">
              <div class="content content-white" ref="print">
                <div class="news__top-line pb-4">
                  <div class="row">
                    <div class="col-6">
                      <div class="news__date">{{ getNewsDate }}</div>
                    </div>
                    <div class="col-6 d-flex justify-content-end">
                      <!--                      <div class="news__share">-->
                      <!--                        <svg-->
                      <!--                          width="24"-->
                      <!--                          height="20"-->
                      <!--                          viewBox="0 0 24 20"-->
                      <!--                          fill="none"-->
                      <!--                          xmlns="http://www.w3.org/2000/svg"-->
                      <!--                        >-->
                      <!--                          <g clip-path="url(#clip0_204_21407)">-->
                      <!--                            <path-->
                      <!--                              d="M16.5 11.875L21 8.125L16.5 4.375"-->
                      <!--                              stroke="#272727"-->
                      <!--                              stroke-width="1.5"-->
                      <!--                              stroke-linecap="round"-->
                      <!--                              stroke-linejoin="round"-->
                      <!--                            />-->
                      <!--                            <path-->
                      <!--                              d="M18 16.875H3.75C3.55109 16.875 3.36032 16.8092 3.21967 16.6919C3.07902 16.5747 3 16.4158 3 16.25V6.875"-->
                      <!--                              stroke="#272727"-->
                      <!--                              stroke-width="1.5"-->
                      <!--                              stroke-linecap="round"-->
                      <!--                              stroke-linejoin="round"-->
                      <!--                            />-->
                      <!--                            <path-->
                      <!--                              d="M7.03357 13.75C7.53308 12.14 8.66022 10.714 10.2379 9.69581C11.8157 8.67766 13.7545 8.12513 15.75 8.125H21"-->
                      <!--                              stroke="#272727"-->
                      <!--                              stroke-width="1.5"-->
                      <!--                              stroke-linecap="round"-->
                      <!--                              stroke-linejoin="round"-->
                      <!--                            />-->
                      <!--                          </g>-->
                      <!--                          <defs>-->
                      <!--                            <clipPath id="clip0_204_21407">-->
                      <!--                              <rect width="24" height="20" fill="white" />-->
                      <!--                            </clipPath>-->
                      <!--                          </defs>-->
                      <!--                        </svg>-->
                      <!--                        <span>Поделиться</span>-->
                      <!--                      </div>-->
                      <!--                      <share-network-->
                      <!--                        v-if="getActualNews.title"-->
                      <!--                        :title="getActualNews.title"-->
                      <!--                        :description="getActualNews.description"-->
                      <!--                        :url="$route.fullPath"-->
                      <!--                        :network="'facebook'"-->
                      <!--                      />-->

                      <div class="news__download" @click="print">
                        <svg
                          width="24"
                          height="20"
                          viewBox="0 0 24 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_204_21412)">
                            <path
                              d="M12 1.87402V14.374"
                              stroke="#272727"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.25 8.75049L12 14.3755L18.75 8.75049"
                              stroke="#272727"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.5 16.874H19.5"
                              stroke="#272727"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_204_21412">
                              <rect width="24" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <span>Скачать</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="news__content" v-html="getActualNews.text" />
              </div>
            </div>
            <div class="col-12 col-md-3 pt-4 pt-md-0">
              <template v-if="$route.name === 'NewsDetail'">
                <h2 class="news__other pb-3">Другие новости</h2>
                <news-item
                  class="pb-4"
                  v-for="news in getOtherNews"
                  :key="news.id"
                  :news="news"
                />
              </template>
              <template v-else-if="$route.name === 'EventDetail'">
                <info-sidebar :info="getMainStock" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { filter, find, take } from "lodash";
import TopLine from "@/components/pages/TopLine";
import NewsItem from "@/components/pages/News/NewsItem";
import TopLineCity from "@/components/pages/TopLineCity";
import html2pdf from "html2pdf.js/src";
import InfoSidebar from "@/components/pages/InfoSidebar";

export default {
  name: "NewsDetail",
  components: { InfoSidebar, TopLineCity, NewsItem, TopLine },
  data() {
    return {
      title: null,
    };
  },
  head: {
    title: function () {
      return {
        inner: this.getActualNews.title || "Страница новости",
      };
    },
  },
  computed: {
    ...mapGetters("content", ["getNews", "getEvents", "getMainStock"]),
    getActualNews() {
      if (this.$route.name === "NewsDetail") {
        return find(this.getNews, (news) => news.id === this.getNewsId) || {};
      } else {
        return (
          find(this.getEvents, (event) => event.id === this.getNewsId) || {}
        );
      }
    },
    getOtherNews() {
      return take(
        filter(this.getNews, (news) => news.id !== this.getNewsId),
        3
      );
    },
    getFilePrefix() {
      return process.env.VUE_APP_FILE_PATH;
    },
    getNewsDate() {
      return this.$moment(this.getActualNews.created_at).format("DD MMMM YYYY");
    },
    getNewsId() {
      return Number(this.$route.params.id);
    },
  },
  watch: {
    getNewsId() {
      this.$emit("updateHead");
    },
  },
  async mounted() {
    if (!this.getNews.length) {
      await this.loadNews();
    }
    if (!this.getEvents.length) {
      await this.loadEvents();
    }
  },
  methods: {
    ...mapActions("content", ["loadNews", "loadEvents"]),
    print() {
      let print = this.$refs.print.innerHTML;
      print = `<div style="padding: 40px"><h1>${this.getActualNews.title}</h1>${print}</div>`;
      console.log(print);
      return html2pdf(print, {});
    },
  },
};
</script>

<style scoped lang="scss">
.news {
  &__other {
    font-style: normal;
    font-weight: 400;
    font-size: em(18);
    /* identical to box height */

    /* #272727 */

    color: #272727;
  }
  &__date {
    font-style: normal;
    font-weight: 400;
    font-size: em(14);
    /* identical to box height */

    text-transform: uppercase;

    color: #fe6500;
  }
  &__download {
    margin-left: 10px;
  }
  &__share,
  &__download {
    cursor: pointer;
    @include media(768) {
      span {
        display: none;
      }
    }
    svg {
      //vertical-align: top;
    }
    span {
      padding-left: 10px;
    }
    * {
      transition: all 0.25s;
    }
    &:hover {
      * {
        color: #fe6500;
        stroke: #fe6500;
      }
    }
  }
}
.back a {
  cursor: pointer;
}
</style>
