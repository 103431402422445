<template>
  <div class="news">
    <div class="position-relative">
      <router-link
        :to="`/${link}/${news.id}`"
        tag="div"
        class="news__image"
        :style="{
          backgroundImage: `url(${getFilePrefix}${news.preview_picture})`,
        }"
      />
      <router-link
        v-if="news.category"
        :to="{
          name: link === 'news' ? 'News' : 'Events',
          query: { category: news.category.id },
        }"
        class="news__tag"
        >{{ news.category.name }}</router-link
      >
    </div>
    <h2 class="news__title pt-2">
      <router-link :to="`/${link}/${news.id}`">
        {{ news.title }}
      </router-link>
    </h2>
    <div class="news__date">{{ getDate }}</div>
  </div>
</template>

<script>
import { find } from "lodash";

export default {
  name: "NewsItem",
  props: {
    news: {
      type: Object,
      required: true,
    },
    link: {
      type: String,
      default: "news",
    },
    types: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    getType() {
      return (
        find(this.types, (type) => type.page === this.news.category) || null
      );
    },
    getDate() {
      return this.$moment(this.news.created_at).format("DD.MM.YYYY");
    },
    getFilePrefix() {
      return process.env.VUE_APP_FILE_PATH;
    },
  },
};
</script>

<style scoped lang="scss">
.news {
  &__image {
    min-height: 340px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
  }
  &__tag {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 3px 24px;
    background: #ff6600;
    border-radius: 10px;
    color: #fff;
    text-decoration: none;
  }
  &__title {
    font-weight: 700;
    font-size: em(18);
    a {
      color: #484848;
      text-decoration: none;
    }
  }
  &__date {
    font-weight: 500;
    font-size: em(14);

    color: #9a9a9a;
  }
}
</style>
